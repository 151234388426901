import Lottie from "lottie-react";
import React from "react";
import { Animation } from "./animation";
import styled from "styled-components";

const LottieWrapperSrttyed = styled.div`
  max-width: 400px;
  margin: 20px auto;
`;

const LottieAnimation = () => {
    const lottieAnimationKey = getRandomValueFromArray(Object.keys(Animation));
    return (
        <LottieWrapperSrttyed>
            <Lottie
                loop={ true }
                animationData={ Animation[lottieAnimationKey] }
            />
        </LottieWrapperSrttyed>
    );
};

export default LottieAnimation;

export const getRandomValueFromArray = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};
