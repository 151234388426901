import * as React from "react";
import styled from "styled-components";
import "../global.css";
import LottieAnimation from "../LottieAnimation";
import Tabs from "../Tabs";

const StyledWrapper = styled.main`
  h1 {
    color: rgb(246, 143, 190);
    font-size: 50px;
    text-align: center;
    margin-top: 60px;

    @media (max-width: 800px) {
      margin-top: 10px;
    }
  }

  h2 {
    font-size: 47px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 800px) {
      font-size: 37px;
    }
  }

  p {
    font-size: 37px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 800px) {
      font-size: 27px;
    }
  }

  a {
    display: inline-block;
    appearance: none;
    cursor: pointer;
    white-space: nowrap;
    max-inline-size: 100%;
    vertical-align: middle;
    font-weight: 500;
    padding-block: 0;
    padding-inline: 12px;
    block-size: 40px;
    font-size: 1rem;
    min-inline-size: 112px;
    border-radius: 6px;
    color: rgb(0, 0, 0);
    background-color: rgb(125, 222, 216);
    outline: none;
    text-align: center;
    line-height: 2.3;
    text-decoration: none;

    transition: transform 250ms ease-in-out 0s, background-color 250ms ease-in-out 0s;

    margin: auto;
    width: 50px;
    display: block;
    margin-top: 60px;


    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const StyledMainContentWrapper = styled.div`
  width: 1200px;
  margin: 10px auto 50px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const IndexPage = () => {
    return (
        <StyledWrapper>
            <StyledMainContentWrapper>
                <LottieAnimation/>
                <h1>Your DREAM came true</h1>
                <h2>Dating in Telegram app</h2>
                <p>Sign in and start chatting</p>

                <a href="https://app.telegramsexchat.com/login">Sign in</a>

                <Tabs/>
            </StyledMainContentWrapper>
        </StyledWrapper>
    );
};

export default IndexPage;

export const Head = () => {
    return (
        <>
            <meta charSet="utf-8"/>
            <meta name="description"
                  content="Dating and sex chat with telegram app, find your couple easily with two clicks"/>
            <title>Telegram dating and sex chat</title>
            <title>Telegram dating and sex chat</title>
        </>
    );
};


